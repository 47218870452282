$largeMobileMenuNavBar: 157.5px;
$largeDesktopMenuNavBar: 245px;
$smallMenuNavBar: 140px;

$CoEvoCharcoal: rgba(103, 103, 104, 1);
$CoEvoGreydark: rgb(184, 185, 187);
$CoEvoGreymedium: rgb(204, 204, 204);
$CoEvoGreylight: rgb(220, 221, 222);
$Greydar-rad-charcoal: radial-gradient($CoEvoGreydark, $CoEvoCharcoal);

$iPhone5: 320px;
$iPhone6-7-8: 414px; 
$iPad: 768px;
$iPadPro: 1024px;
$sideNav: 100px;
$mobileMenuIcon: 570px;

.clear-rad-black {
    background: radial-gradient(rgba(255, 255, 255, 0), black);
    background-image: radial-gradient(rgba(255, 255, 255, 0), black);
}

.black-rad-clear {
    background-image: radial-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, 0.25));
}

.coevo-grey-dark {
    background-color: $CoEvoGreydark;
}

.coevo-grey-medium {
    background-color: $CoEvoGreymedium;
}

.coevo-grey-light {
    background-color: $CoEvoGreylight;
}

.coevo-charcoal {
    background-color: $CoEvoCharcoal;
}

.coevo-charcoal-text {
    color: $CoEvoCharcoal;
}

.greydar-rad-charcoal {
    background: $Greydar-rad-charcoal;
}

.greydar-rad-charcoal-transparent-opaque {
    background: radial-gradient(rgba(184, 185, 187, 0), rgba(103, 103, 104, 1));
}

.white-text {
    color: white;
}

.sls-orange-text {
    color: $slsDarkOrange;
}


.appear {
    opacity: 0;
    transition: .8s ease;
}

.banner-div-bottom {
    position: absolute;
    bottom: 0%;
    height: 16%;
    width: 100%; 
    background-color: $slsDarkOrange;
    opacity: 70%;
    text-align: center;

    h3 {
        font-size: .9em;
        margin-top: 8px;
    }

    @media screen and (max-width: $iPhone5) {
        h3 {
            font-size: .8em;
        }
    }

    @media screen and (min-width: $iPad) {
        h3 {
            font-size: 2em;
        }
        h1 {
            margin-top: 20px;
            font-size: 2.25em;
        }
    }

    @media screen and (min-width: $iPadPro) {
        h1 {
            font-size: 2.75em;
        }
    }
}

.banner-fvh-mod {
    height: 20%;
    
    @media screen and (min-width: $iPad) {
        max-height: 125px;
    }

    @media screen and (max-width: $iPhone5) {
        height: 40%;
        top:  80%;
    }
}

.carousel {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: visible;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    .img-box {
        margin-left: 25px;
        margin-top: 25px;
    }

    img {
        margin-left: 25px;
        margin-top: 25px;
        cursor: pointer;
    }
    img:hover {
        -webkit-transform:scale(1.25); /* or some other value */
        transform:scale(1.25);
        -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0,0.5);
        -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
        box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
    }
}

.faux-button {
    background-color: $slsDarkOrange;
    color: white;
    padding: 1em 1.5em;
    text-decoration: none;
    text-transform: uppercase;
}

.faux-button:hover {
    background-color: rgb(172, 89, 0);
}

.faux-button:active {
    background-color: $slsGreen;
}

.faux-button:visited {
    background-color: $slsGrey;
}

.flex-container-column {
    display:flex;
    flex-direction: column;
}

.flex-container-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-item {
    display:flex;
    flex: 1;
    -ms-flex: 1; 
    padding: 20px 20px;
    text-align: center;

    @media screen and (min-width: $iPad) {
        padding: 40px 40px;
        p {
            font-size: 2.0em;
        }
    }
}

.full-content-container {
    display: flex;
    flex-direction: column;
}

.full-box-overlay {
    position: absolute; 
    height: 100%;
    width: 100%; 
}

.full-size-overlay-sub-menu-large {
    position: absolute;
    width: 100vw;
    height: calc(100vh - #{$largeMobileMenuNavBar});
}

.full-view-height-sub-menu-large {
    position: relative;
    width: 100vw; 
    height: calc(100vh - #{$largeMobileMenuNavBar});
   
    @media screen and (min-width: $iPad) {
        height: calc(100vh - #{$largeDesktopMenuNavBar});
        margin-top: 0px;

        img {
            max-height: 417px;
            max-width: 417px;
        }
    }
}

.full-width-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: inline-block;
}


.half-height-content-container {
    position: relative;
    width: 100%; 
    height: 50%; 
}

.half-width-container {
    position: relative;
    width: 50%;
    height: 100%;
    display: inline-block;
}

.half-width-container:hover .appear{
    opacity: .5;
}


.ice-management {
    background: radial-gradient(rgba(255, 255, 255, 0), black), url(./images/ice-danger-winter-storms-icicles-hanging.png);
    
    @media screen and (min-width: 320px) {
        background: radial-gradient(rgba(255, 255, 255, 0), black), url(./images/ice-removal-winter-storms-icicles-hanging-mobile.png);
    }
}


.lotplowing-bg {
    background-image: url(./images/snow-plowing/SLS-Landscaping-Snow-Plowing-truck-clearing-parking-lot-Columbia-MD.jpg);

    @media screen and (max-width: 414px), (max-height: 823px) {
        background-image: url(./images/snow-plowing/SLS-Landscaping-Snow-Plowing-truck-clearing-parking-lot-Columbia-MD-mobile.jpg);
    }

    @media screen and (min-width: 767px) and (max-width: 1024px) {
        background-image: url(./images/snow-plowing/SLS-Landscaping-Snow-Plowing-truck-clearing-parking-lot-Columbia-MD-tablet.jpg);
    }
}


.overlay-appear-text {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 75%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}


.quarter-height-full-width-container {
    position:relative;
    width: 100%; 
    height: 25vh;
}


.side-slide {
    display: inline-block;
}

.snowplowing-bg {
    background-image: url(./images/SLS-Landscaping-Snow-Plowing-Commercial-Lot-Columbia-MD.jpg);

    @media screen and (max-width: $iPhone6-7-8) {
        background-image: url(./images/SLS-Landscaping-Snow-Plowing-Commercial-Lot-Columbia-MD-mobile.jpg);
    }

    @media screen and (min-width: 374px) and (max-width: 414px) {
        background-image: url(./images/SLS-Landscaping-Snow-Plowing-Commercial-Lot-Columbia-MD-mobile-large.jpg);
    }

    @media screen and (min-width: 767px) and (max-width: 1024px) {
        background-image: url(./images/SLS-Landscaping-Snow-Plowing-Commercial-Lot-Columbia-MD-tablet.jpg);
    }
}

.third-height-content-container {
    position: relative;
    height: 33vh;
    width: 100%;
}

.walkwayclearing-bg {
    background-image: url(./images/snow-plowing/SLS-Landscaping-snowblowing-Howard-County-MD-business-park.jpg);

    @media screen and (max-width: 414px), (max-height: 823px) {
        background-image: url(./images/snow-plowing/SLS-Landscaping-snowblowing-Howard-County-MD-business-park-mobile.jpg);
    }

    @media screen and (min-width: 767px) and (max-width: 1024px) {
        background-image: url(./images/snow-plowing/SLS-Landscaping-snowblowing-Howard-County-MD-business-park-tablet.jpg);
    }
}
