#services-content {
    background-color: rgba(255, 255, 255, 0.7);
    max-width: 100%;

    #services-header-wrapper {
        margin: 20px auto 0px;
        max-width: 960px;
        padding: 20px;
        font-size: 20px;
        text-align: center;

        h2 {
            display: inline;
            opacity: 0;
            color: $slsBrown;
            text-shadow: $textShadowWhite;
        }

        @media screen and (min-width: 565px) {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
        }
    }

    .serviceCategory {
        display: flex;
        flex-flow: column nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;


        margin: 20px auto;
        padding: 0px 20px;
        max-width: 960px;
        min-height: 170px;

        overflow: auto;

        .categoryHeaderWrapper {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            margin: 0px auto;
            padding: 60px 20px;

            width: 100%;
            height: 150px;

            opacity: 0;

            background-color: $slsDarkOrange;
            color: white;
            box-shadow: $boxShadow1;
            border-radius: $borderRadius2;
            border: 10px solid white;

            h3 {
                display: block;
                font-size: 1.5em;
                padding: 5px 0px;
                border-radius: $borderRadius2;
                text-shadow: $textShadow1;
            }

            cursor: pointer;
        }

        ul.serviceList {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;

            padding: 0px;

            width: 100%;
            height: 0px;

            overflow: hidden;

            img {
                display: none;
            }

            li {
                flex: .5 1 calc((100% / 3) - 10px);

                padding: 5px;
                margin: 10px 5px;

                background-color: $whiteTransparent2;
                border-radius: $borderRadius2;
                box-shadow: $boxShadow1;
                color: $slsDarkOrange;

                font-size: .8em;
                font-weight: bold;
                list-style-type: none;
                text-align: center;
                // white-space: nowrap;

                cursor: pointer;
            }

        }

        ul.serviceInfoContainer {
            margin: 0px auto;
            padding: 0px 10px;

            width: 100%;
            height: 100%;

            overflow: hidden;

            li {
                height: 0px;
                width: 100%;
                overflow: hidden;

                p {
                    font-size: 1.2em;
                    font-weight: bold;
                }
            }

        }

        .dividerLine {
            display: block;
            box-sizing: content-box;
            height: 1px;
            margin: 10px 0px 15px;
            background-color: $slsGrey;
        }

        .scrollUp {
            display: block;
            box-sizing: content-box;
            height: 30px;
            margin-top: 10px;
            padding: 0px;

            border-radius: $borderRadius2;
            background-color: $slsDarkOrange;
            color: white;
            font-size: 1em;
            font-weight: bold;
            line-height: 30px;
            text-align: center;

            @media screen and (min-height: 821px) {
                display: none;
            }

        }
    }

    @import 'services_340';
}