/* ---------------- _footer.scss ---------------- */
footer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    box-sizing: content-box;
    overflow: auto;

    padding: 5px 0px;
    min-height: 240px;
    width: 100vw;

    background-color: $slsYellow;
    border-top: 2px solid $slsDarkOrange;

    #footer-layout {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;

        margin: 0px auto 40px;
        max-width: 980px;
        min-height: 200px;

        #footer-nav {
            margin: 0px;
            padding: 20px;

            ul {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: flex-start;
                align-content: flex-start;

                height: 100%;


                li {
                    margin: 5px 10px;
                    font-weight: bold;
                    list-style: none;
                    white-space: nowrap;

                    a {
                        color: $slsDarkOrange;
                        font-size: 20px;
                        text-decoration: none;


                        &.active {
                            color: $slsGreen;
                        }
                    }
                }
            }
        }

        #social-media-links {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-content: center;
            align-items: center;

            padding: 10px 10px;
            max-height: 165px;

            a {
                margin: 0px 10px;
                width: auto;
                height: auto;


                background-color: $slsOrange;
                border-radius: $borderRadius2;

                img {
                    display: block;
                    box-sizing: content-box;
                    max-width: 54px;
                    max-height: 54px;
                    width: 100%;
                    height: auto;

                    border-radius: $borderRadius2;
                    border: 2px solid $slsOrange;
                }
            }

        }

        /* Block containing contact information */
        #footer-info {
            padding: 20px 20px 0px;
            text-align: center;

            p {
                font-size: 24px;
                font-weight: bold;
                color: $slsGrey;

            }

            a.phone {
                display: inline-block;
                color: $slsDarkOrange;
                margin-top: 10px;
                text-decoration: none;
                font-size: 24px;
                font-weight: bold;

                span {
                    color: $slsGreen;
                    margin-right: 5px;
                }
            }

        }
    }

    /* Legal notice at the bottom of the footer */
    small {
        justify-self: flex-end;
        height: 40px;
        padding: 0px 2.5%;

        font-size: .8em;
        text-align: center;

        a {
            color: $slsDarkOrange;
            text-decoration: none;
        }
    }

}