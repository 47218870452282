@media screen and (min-width: 340px) {
    .serviceCategory {
        .categoryHeaderWrapper {
            padding: 40px 20px;

            @media screen and (min-width: 555px) {
                padding: 20px 20px;
            }
        }

        ul.serviceList {
                img {
                    display: block;

                    margin: 10px auto 0px;
                    border: 4px solid $slsDarkOrange;
                    width: 100%;
                    max-width: 908px;
                    max-height: 200px;
                }

                li {
                    flex: 0 1 calc((100% / 4) - 10px);
                    font-size: 1em;
                    white-space: nowrap;
                }
        }

    }
}