#about-content {
    display: flex;
    flex-flow: row wrap;

    .aboutPost {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        
        margin: 40px;
        max-width: 380px;

        border-radius: $borderRadius4;
        cursor: pointer;

        &:hover {
            background-color: rgba(255, 153, 45, .80);
        }

        &>img {
            display: block;
            box-sizing: content-box;

            margin: 20px auto;
            width: 100%;
            max-width: 250px;
            height: 100%;
            max-height: 200px;

        }

        .contactUs {
            margin: 70px auto;
            padding: 0px;

            max-width: 250px;
            max-height: 100px;

            line-height: 100px;
            border-radius: $borderRadius4;
            box-shadow: $boxShadow1;
        }

        @media screen and (max-width: 250px) {

            &>img {
                margin: 0px;
                border-radius: $borderRadius4;
            }

            .labelTop,
            .labelBottom {
                display: none;
            }

            .contactUs {
                font-size: 1em;
                margin: 0px;
            }
        }

        @media screen and (max-width: 960px) {
            margin: 20px auto;
        }

        .modal {
            padding-top: calc(10vh + 20px);

            .aboutModal {
                padding: 30px;
                margin: 0px auto;

                width: 100%;
                max-width: 960px;
                height: 100%;
                max-height: calc(80vh);

                background-color: rgb(227, 255, 235);
                border-radius: 15px;
                box-shadow: $boxShadow0;
                text-align: left;

                overflow-x: hidden;
                overflow-y: auto;

                cursor: auto;

                .close {
                    display: inline-block;
                    width: 100%;
                    padding-right: 20px;

                    color: #aaaaaa;
                    font-size: 40px;
                    font-weight: bold;
                    text-align: right;

                    &:hover,
                    &:focus {
                        color: black;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }

                h4 {
                    display: block;
                    width: 100%;
                    font-size: 50px;
                    color: #484848;
                    margin: 0px 0px 20px;
                    text-align: center;
                }

                h5 {
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 28px;
                    margin: 40px auto 20px;
                }

                p {
                    color: #484848;
                    font-weight: bold;
                    line-height: 30px;

                    &>img {
                        display: block;
                        margin: 20px auto;
                        width: 100%;
                        max-width: 400px;
                        height: 100%;
                        max-height: 200px;
                        border: 5px rgb(255, 153, 45) solid;
                    }
                }
            }
        }
    }
}