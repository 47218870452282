#contact-content {
    .postContact {
        background-color: rgba(255, 153, 45, .8);
    }

    #form-area {
        background-color: #fbebd5;
        box-shadow: $boxShadow0;
        margin: 40px auto;

        width: 100%;
        max-width: 600px;

        box-sizing: border-box;
        padding: 30px;

        h3 {
            font-size: 30px;
            text-align: center;
            margin-bottom: 20px;

            @media screen and (max-width: 470px) {
                text-align: left;
            }
        }

        p {
            color: black;
            margin-bottom: 20px;
        }



        #contact-form {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: center;

            margin: 0px auto;
            width: 100%;
            max-width: 400px;

            #info-area {
                display: flex;
                flex-flow: row wrap;

                width: 100%;

                .name {
                    width: 20%;
                    text-align: right;
                    padding-right: 10px;
                    margin: 3px auto;
                }

                .cell {
                    flex-grow: 1;
                    margin: 3px auto;
                    width: 80%;

                    input {
                        width: 80%;

                        &:focus {
                            background-color: rgba(237, 46, 20, .25);
                        }

                    }

                    textarea {
                        width: 80%;
                        height: 100px;
                        resize: none;
                    }
                }

                @media screen and (max-width: 420px) {
                    display: block;

                    .name,
                    .cell {
                        text-align: left;
                        margin: 3px 0px;
                        width: 100%;
                    }
                }
            }

        }

        .buttons {
            flex: 1;
            width: 60%;
            margin: 10px 0px;

            input {
                margin-right: 10px;
            }

            @media screen and (max-width: 420px) {
                width: 100%;
            }
        }

    }

    hr {
        margin: 20px 0px;
    }

    img {
        display: block;
        margin: 30px auto;
        width: 100%;
        height: auto;
        max-width: 400px;
        max-height: 150px;
    }

    // Some stuff for the error and and thank you pages
    h4 {
        text-align: left;
        font-size: 1.2em;
        margin-bottom: 20px;
    }

    .contactUs {
        background: none;
        color: $slsDarkOrange;
        text-shadow: none;
        display: block;
        margin: 0px auto;
        font-size: 18px;
        text-decoration: none;

        &:hover {
            color: $slsGreen;
        }
    }

}