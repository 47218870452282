/* ---------------- _navbar.scss ---------------- */
#nav-bar {
    position: fixed;
    top: 0px;
    z-index: 9999;

    width: 100%;
    height: auto;

    box-shadow: $boxShadow2;
    background-color: $slsDarkOrange;

    #nav-drop-down {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        height: 40px;

        @import 'menu_icon';

        p {
            height: 40px;

            font-weight: bold;
            font-size: 1.2em;
            line-height: 40px;
            color: white;
        }
    }

    ul {
        display: flex;
        flex-flow: column nowrap;

        height: 0px;

        overflow: hidden;
        list-style-type: none;

        li {
            flex: 1;
            a {
                display: block;

                padding-left: 10px;
                height: 100%;
                width: 100%;

                background-color: $slsYellow;
                color: $slsGrey;
                text-align: start;
                font-weight: bold;
                font-size: 20px;
                line-height: 5vh;
                text-decoration: none;

                transition-duration: .2s;
                transition-timing-function: linear;

                &.active {
                    background-color: $slsGreen;
                    color: white;
                    text-shadow: $textShadow1;
                }
            }
        }
    }

}

// ------------------ BREAKPOINT IMPORTS ------------------
@import 'navbar_720';
// --------------------------------------------------------