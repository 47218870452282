#menu-icon {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;

    #bar1,
    #bar2,
    #bar3 {
        width: 20px;
        height: 4px;
        background-color: white;
        margin: 4px 0px;
        transition-duration: .3s;
        transition-timing-function: ease-out;
    }

    &.open {

        #bar1,
        #bar3 {
            margin: 0px;
            padding: 0px;
            height: 0px;
            opacity: 0;
        }

        #bar2 {
            box-sizing: border-box;
            border-top: 4px solid white;
            border-left: 4px solid white;
            width: 15px;
            height: 15px;
            padding: 3px 0px;
            margin: 3px 3px;
            background-color: transparent;
            transform: rotate(45deg) translate(3px, 3px);
        }

    }

}