/* Portfolio Page Wrappers */
#portfolio-content {
    background-color: rgba(255, 255, 255, 0.7);
    max-width: 100%;

    h2 {
        margin: 20px auto;
        padding-left: 30px;
        max-width: 960px;

        color: $slsDarkOrange;
        text-shadow: $textShadow1;
        font-size: 40px;
        text-align: left;
    }

    .subHeaderWrapper {
        height: auto;
        width: 100%;

        background-color: $slsDarkOrange;

        h4 {
            margin: 0px auto;
            padding-left: 30px;
            max-width: 960px;

            color: white;
            text-shadow: $textShadow1;

            font-weight: bold;
            font-size: 18px;
            line-height: 30px;
            text-align: left;
        }
    }

    #portfolio-thumbnails {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        margin: 20px auto;
        padding: 5px;

        width: 100%;
        max-width: 960px;
        height: 100%;

        overflow: auto;

        .thumb {
            flex: 0 1 220px;
            height: 180px;
            margin: 5px;
            cursor: pointer;

            box-sizing: content-box;
            border: 3px solid white;

            img {
                width: 220px;
                height: 180px;
                margin: 0px;
                padding: 0px;
            }

            &:hover {
                box-shadow: 0px 1px 10px black;
            }

            &>modal {
                cursor: auto;
            }
        }
    }

    #portfolio-modals {
        .modal {
            overflow: auto;

            .close {
                display: block;
                margin: 20px 10vw 20px 0px;

                color: white;
                font-size: 60px;
                line-height: 60px;
                text-align: right;

                cursor: pointer;

                &:hover {
                    color: black;
                }
            }

            .portfolioModal {
                margin: 0px auto;
                max-width: 960px;
                height: 100%;

                display: grid;

                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto auto 1fr;


                img {
                    grid-column: 1 / span 2;
                    grid-row: 1;

                    width: 100%;
                    max-width: 658px;
                    max-height: 437px;

                    box-shadow: $boxShadow0;
                    cursor: auto;
                    justify-self: center;
                }

                .prevBtn,
                .nextBtn {
                    background: none;
                    border: none;
                    margin: 20px 0px;
                    height: 80px;

                    align-self: center;
                    cursor: pointer;

                    i {
                        box-sizing: content-box;
                        width: 12px;
                        height: 12px;
                        border:  solid #EEEEEE;
                        border-width: 0px 8px 8px 0px;
                        display: inline-block;
                        padding: 3px;

                        &:hover {
                            border-color: black;
                        }
                    }

                }

                .prevBtn {
                    grid-column: 1;
                    grid-row: 2;
                    justify-self: center;
                    padding-left: 40px;

                    i {
                        transform: rotate(-225deg);
                    }
                }

                .nextBtn {
                    grid-column: 2;
                    grid-row: 2;
                    justify-self: center;
                    padding-right: 40px;

                    i {
                        transform: rotate(-45deg);
                    }
                }

                p {
                    grid-column: 1 / span 2;
                    grid-row: 3;
                    margin: 20px 0px;
                    font-size: 26px;
                    color: white;
                    font-weight: bold;
                    cursor: auto;
                }
            }
        }
    }
}

@import 'portfolio_960';