/* ---------------- _navbar_720.scss ---------------- */
@media screen and (min-width: 720px) {
    #nav-bar {
        box-shadow: none;
        background-color: lighten($slsYellow, 4);

        #nav-drop-down {
            // Disable the drop-down style menu on wider screens
            display: none;
        }

        ul {
            flex-flow: row nowrap;
            margin: 0px auto;
            padding: 2px 5px;

            max-width: 960px;
            height: 40px;

            transition: none;

            li {

                margin: 0px 5px;

                border-radius: $borderRadius1;
                

                a {
                    padding: 0px;

                    color: white;
                    text-shadow: $textShadow1;
                    font-size: 22px;
                    line-height: 36px;
                    text-align: center;

                    border-radius: $borderRadius1;
                    background-color: $slsDarkOrange;
                }

            }
        }
    }
}