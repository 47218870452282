/* ---------------- _globals.scss ---------------- */

// Sitewide colors
$slsYellow: #FFFFB4;
$slsOrange: rgb(255, 153, 45);
$slsDarkOrange: #FF642D;
$slsGreen: rgb(0, 166, 45);
$slsGrey: #444444;
$slsBrown: #65391C;

$borderRadius1: 5px;
$borderRadius2: 10px;
$borderRadius3: 15px;
$borderRadius4: 30px;

$boxShadow0: 0px 2px 5px black;
$boxShadow1: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
$boxShadow2: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
$textShadow1: 1px 1px 2px rgba(0, 0, 0, 0.8);
$textShadowWhite: 1px 2px 2px white;

$whiteTransparent1: rgba(255, 255, 255, 0.9);
$whiteTransparent2: rgba(255, 255, 255, 0.6);

// Device height - navbar - aprox. header - footer
$contentHeight: calc(100vh - 40px - 100px - 240px);

.sls-lightest-orange {
    background-color: rgb(255, 225, 194);
}

.sls-lightest-green {
    background-color: rgb(213, 255, 224);
}

.sls-winter-blue {
    background-color: rgb(214, 238, 255);
}

.sls-brown {
    background-color: $slsBrown;
}

.sls-brown-text {
    color: $slsBrown;
}


* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    overflow-y: scroll;

    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center top;

    font-family: Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;
    color: $slsGrey;

}

.grass-bg {
    background-image: linear-gradient(rgba(0, 0, 0, .1),
    rgba(0, 0, 0, 0.1)), url('./images/sls_bg.jpg');
}

/* Main Area Wrapper */
.contentWrapper {
    width: 100vw;
    min-height: $contentHeight;

    .content {
        margin: 0px auto;
        width: 100%;
        max-width: 960px;
        min-height: $contentHeight;

        overflow: auto;

        .post {
            background-color: rgba(255, 255, 255, 0.80);
            width: 100%;
            max-width: 960px;
            margin: 40px auto;

            box-shadow: $boxShadow0;
            border-radius: $borderRadius4;

            .labelTop,
            .labelBottom {
                width: 100%;
                max-width: 960px;
                min-height: 40px;
                font-size: 26px;
                line-height: 40px;

                color: white;
                text-shadow: $textShadow1;
                text-align: center;
                background-color: $slsDarkOrange;
            }

            .labelTop {
                border-top-left-radius: $borderRadius4;
                border-top-right-radius: $borderRadius4;
            }

            .labelBottom {
                border-bottom-left-radius: $borderRadius4;
                border-bottom-right-radius: $borderRadius4;
            }
        }

        .home-button-container {
            width: 100%;
            max-width: 960px;
            margin: 40px auto;
        }
    }
}

.modal {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.75);

    width: 100vw;
    height: 100%;
    min-height: $contentHeight;
    padding-top: 40px;

    background-color: rgba(0, 0, 0, 0.75);

    z-index: 2;

}

.contactUs {
    width: 100%;

    background-color: $slsDarkOrange;
    color: white;
    text-shadow: $textShadow1;

    font-weight: bold;
    font-size: 36px;
    text-align: center;

    &:hover {
        background-color: #00A62D;
        cursor: pointer;
    }
}

.bk-img-logo {
    background-image: url(./images/sls-logo-icon-mobile.png);
    background-repeat: no-repeat;
    background-position: center;
}