#home-content {
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 100%;

    .homePost {
        background-color: rgba(255, 153, 45, .80);
        max-width: 960px;
        margin: 40px auto;

        .labelTop {
            font-size: 30px;

            @media screen and (max-width: 420px) {
                font-size: 24px;
            }
        }

        .labelBottom {
            font-size: 24px;

            @media screen and (max-width: 420px) {
                font-size: 18px;
            }
        }

    }

    /* Slides Section */
    #slides-section {
        max-width: 960px;
        height: auto;

        border-radius: $borderRadius4;

        .slide {
            display: none;

            width: 100%;
            max-width: 960px;
            height: 100%;
            max-height: 300px;

            &#first {
                display: block;
            }

            img {
                display: block;
                width: 100%;
                max-width: 960px;
                max-height: 300px;
            }
        }
    }

    /* Videos Section */
    #vidSection {
        border-radius: $borderRadius4;

        .vidContainer {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            padding: 20px 0px;
            height: 100%;

            iframe {
                flex: 0 1 336px;
                margin: 20px 20px;
                height: 188px;

                border: none;
                box-shadow: $boxShadow0;
                cursor: pointer;
            }
        }
    }

    .contactUs {
        display: inline-block;
        margin: 60px auto;

        width: 48%;
        max-width: 480px;
        height: 100px;
        margin: 1%;

        box-shadow: $boxShadow0;
        border-radius: $borderRadius4;
        text-decoration: none;
        line-height: 100px;

        @media screen and (max-width: 420px) {
            font-size: 24px;

            @media screen and (max-width: 250px) {
                    font-size: 1em;
            }
        }
    }

    // /* Blog Section Wrappers */
    // #blogBox {
    //     width: 960px;
    //     margin: 0px auto;
    //     padding: 0px;
    //     overflow: auto;
    //     border-radius: $borderRadius4;

    //     #blogSubscribe {
    //         margin: 20px 0px;
    //         width: 100%;
    //         height: 60px;

    //         /* Subscribe Links */
    //         .subscribePost {
    //             display: block;
    //             width: 390px;
    //             height: 60px;
    //             margin-left: 43px;
    //             margin-right: 43px;
    //             float: left;
    //             background-color: #FF642D;
    //             color: white;
    //             cursor: pointer;
    //             border-top: 5px solid #FF642D;
    //             border-bottom: 5px solid #FF642D;
    //             border-radius: 7px;
    //             box-shadow: 0px 1px 3px black;

    //             &:link,
    //             &:visited,
    //             &:active {
    //                 color: white;
    //                 text-decoration: none;
    //             }

    //             &:hover {
    //                 color: white;
    //                 background-color: #00a62d;
    //                 border-color: #00a62d;
    //             }

    //             img {
    //                 float: left;
    //                 width: 40px;
    //                 height: 40px;
    //                 padding: 0px;
    //                 margin: 5px 10px 10px;
    //             }

    //             p {
    //                 height: 60px;
    //                 width: 330px;
    //                 margin-top: 0px;
    //                 margin-bottom: 0px;
    //                 padding-top: 7px;
    //             }
    //         }
    //     }
    // }
}