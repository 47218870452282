/* ---------------- _header.scss ---------------- */
#header-banner {
    margin-top: 40px;
    width: 100vw;
    background-color: $slsYellow;
    border-bottom: 2px solid $slsDarkOrange;

    img {
        display: block;

        margin: 0px auto;
        width: 100%;
        height: 100%;
        max-height: 300px;
    }
}